import React from 'react';
import './Ourwrk.css';
import Slicksld from './component/Slicksld';


function Ourwrk() {
  return (

    <div id="prj_wrap">
      <section className='prj_hero_sec'>
        <div id="prj_hero">
          <div id="prj_hero_cntnt">
            <h1>Explore our creations and innovative solutions</h1>
          </div>
          <div><Slicksld /></div>
        </div>
      </section>
      <div id="our_wrks" >
      <div id="wrk1"><img src="./Luxgenic_prj_gf.gif" className="prj_imgs"/></div>
      <div id="wrk1"><img src="./Luxgenic_prj_gf2.gif" className="prj_imgs"/></div>
      <div id="wrk1"><img src="./Luxgenic_prj_gf3.gif" className="prj_imgs"/></div>
      <div id="wrk1"><img src="./Luxgenic_prj_gf4.gif" className="prj_imgs"/></div>
      <div id="wrk1"><img src="./Luxgenic_prj_gf5.gif" className="prj_imgs"/></div>
      </div>
    </div>
  );
}
export default Ourwrk;