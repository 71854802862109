import React from 'react';
import UiCarus from'./Uicaurs.jsx';
import './Uitechstk.css';
const Uitech_stack = () => {
  return (
   <div id="uitech_stk_cntnr">
    <div id="ui_tecsec">
<div id="ui_debts">
 <div id="uitechstk_hd"><h1>Technology stack</h1></div>
 <div id="uicrs_cntnr">
 <UiCarus/>
 </div>
 </div>
 </div>
 </div>
  )
}

export default Uitech_stack