import React from 'react';
import Carus from'./Caurs.jsx';
import './Tech_stack.css';
const Tech_stack = () => {
  return (
   <div id="tech_stk_cntnr">
    <div id="web_sec4">
<div id="web_debts">
 <div id="techstk_hd"><h1>Technology stack</h1></div>
 <div id="crs_cntnr">
 <Carus/>
 </div>
 </div>
 </div>
 </div>
  )
}

export default Tech_stack