import React, { useEffect } from "react";
import "./App.css";
import "./Luxgenic/styles/globalstyle.css";
import Home from "./Luxgenic/pages/Home.jsx";
import About from "./Luxgenic/pages/About.jsx";
import Project from "./Luxgenic/pages/Project.jsx";
import Mvp from "./Luxgenic/pages/Mvp.jsx";
import Services_page from "./Luxgenic/pages/Services_page.jsx";
import Connect from "./Luxgenic/Connect.jsx";
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";
import Navbar from "./Luxgenic/Navbar";
import Footer from "./Luxgenic/Footer";
import Web from "./Luxgenic/Services_pgs/Web.jsx";
import Ui from "./Luxgenic/Services_pgs/Ui.jsx";
import Datamng from "./Luxgenic/Services_pgs/Datamng.jsx";
import Appserv from "./Luxgenic/Services_pgs/Appserv.jsx";
import Digital_marketing from "./Luxgenic/Services_pgs/Digital_marketing.jsx";
import Cloud from "././Luxgenic/Services_pgs/Cloud.jsx";


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services_page" element={<Services_page />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/Mvp" element={<Mvp />} />
          <Route path="/Connect" element={<Connect />} />
          <Route path="/Web" element={<Web />} />
          <Route path="/Ui" element={<Ui />} />
          <Route path="/Appserv" element={<Appserv/>} />
          <Route path="/Datamng" element={<Datamng/>} />
          <Route path="/Digital_marketing" element={<Digital_marketing />} />
          <Route path="/Cloud" element={<Cloud/>} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
