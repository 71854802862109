import React from 'react';
import Cloudcarus from'./Cloudcarus.jsx';
import './Cldstk.css';
const Cloudtchkstk= () => {
  return (
   <div id="cld_tech_stk_cntnr">
    <div id="cld_tec_sec">
<div id="cld_debts">
 <div id="cldtechstk_hd"><h1>Technology stack</h1></div>
 <div id="cldcrs_cntnr">
 <Cloudcarus/>
 </div>
 </div>
 </div>
 </div>
  )
}

export default Cloudtchkstk