import React from "react";
import './Datamng.css';
import Dataslider from "./Dataslider";
import Datatchstk from "./Datatchstk";
import { Link } from 'react-router-dom';
export default function datamng() {
  return (
    <div id="data_wrpr">
      <div><Dataslider /></div>
      <div id="dtb_services">
        <h1>What we provide?</h1>
        <div id="dtb_services_cntnr">
          <div className="dtb_services_cntnt">
            <div className="dtbsev_img">
              <img src="./serv_imgs/serv_icons/database.png" />
            </div>
            <div className="dtbsev_pass">
              <h1> Data Acquisition & Integration</h1>
              <p>We excel in ETL services, extracting, transforming, and loading data from diverse sources efficiently. Our expertise extends to automated data ingestion pipelines and sensor data management for IoT devices.</p>
            </div>
          </div>
          <div className="dtb_services_cntnt">
            <div className="dtbsev_img">
              <img src="./serv_imgs/serv_icons/dtbsec.png" />
            </div>
            <div className="dtbsev_pass">
              <h1>Data Security & Compliance</h1>
              <p>We manage access control and permissions, ensuring data security with encryption solutions. Our services include compliance with data privacy regulations and developing data governance frameworks for quality, consistency, and security.
              </p>
            </div>
          </div>
          <div className="dtb_services_cntnt">
            <div className="dtbsev_img">
              <img src="./serv_imgs/serv_icons/dtbprcs.png" />
            </div>
            <div className="dtbsev_pass">
              <h1>Data Processing</h1>
              <p>We specialize in data cleansing and transformation, enhancing data quality for analysis. Our services include BI tools and dashboards for visualization and ML/AI integration for predictive modeling and uncovering hidden patterns.</p>
            </div>
          </div>
          <div className="dtb_services_cntnt">
            <div className="dtbsev_img">
              <img src="./serv_imgs/serv_icons/dtbmdrn.png" />
            </div>
            <div className="dtbsev_pass">
              <h1>Data Modernization</h1>
              <p>
Data Modernization involves upgrading data infrastructure to meet modern needs. It includes migrating to the cloud for scalability and cost efficiency. Data Integration consolidates information for unified analysis. Virtualization allows querying data from multiple sources without replication.</p>
            </div>
          </div>
        </div>
      </div>
      <div><Datatchstk/></div>
      <section id="datamng_prc_sec">
        <h1>Pricing</h1>
        <div id="datamng_prc_cntnr">
          <div id="datamng_prc_cntnt" className='dtm_basic'>
            <h1>Basic</h1>
            {/* <h1>$300</h1> */}
            <hr/>
            <h2>Basic CRM</h2>
            <Link to="/Connect">
            <button class="btn">Get started</button>
            </Link>
          </div>
          <div id="datamng_prc_cntnt" className='dtm_pro'>
            <h1>Pro</h1>
            {/* <h1>$1500</h1> */}
            <hr />
            <h2>CRM with full integration</h2>
            <Link to="/Connect">
            <button class="btn">Get started</button>
            </Link>
          </div>
          <div id="datamng_prc_cntnt" className='dtm_adv'>
            <h1>Advanced</h1>
            {/* <h1>$700</h1> */}
            <hr />
            <h2>CRM with analytics</h2>
            <Link to="/Connect">
            <button class="btn">Get started</button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  )
}