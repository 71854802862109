import React from "react";
import './Appserv.css';
import Appslider from './Appslider'
import Apptchstk from './Apptchstk';
import { Link } from 'react-router-dom';
export default function App() {
    return (
        <div className="App_wrapper">
            <div><Appslider/></div>
            <div id="app_services">
        <h1>What we provide?</h1>
        <div id="app_services_cntnr">
          <div className="app_services_cntnt">
            <div className="appsev_img">
              <img src="./serv_imgs/serv_icons/andrd.png" />
            </div>
            <div className="appsev_pass">
              <h1>Android App development</h1>
              <p>Don't get bogged down by development complexities. Our Android app development service takes care of everything - from concept and design to coding and deployment. You bring the vision, we bring the expertise to turn it into a reality.
</p>
            </div>
          </div>
          <div className="app_services_cntnt">
            <div className="appsev_img">
              <img src="./serv_imgs/serv_icons/api.png" />
            </div>
            <div className="appsev_pass">
              <h1>API development</h1>
              <p>Don't reinvent the wheel! Our API as a service provides pre-built, secure functionalities you can integrate into your applications. Access valuable data, enhance user experiences, and even create new revenue streams by leveraging our APIs without managing the complexities of in-house development. We handle the infrastructure and security so you can focus on building amazing applications
              </p>
            </div>
          </div>
          <div className="app_services_cntnt">
            <div className="appsev_img">
              <img src="./serv_imgs/serv_icons/mntnc.png" />
            </div>
            <div className="appsev_pass">
              <h1>App maintenance and support</h1>
              <p>Never let your app become outdated! Our App Maintenance & Support wing ensures it stays bug-free, secure, and up-to-date with the latest features users crave. We handle everything behind the scenes, so you can focus on app growth and user engagement.</p>
            </div>
          </div>
          <div className="app_services_cntnt">
            <div className="appsev_img">
              <img src="./serv_imgs/serv_icons/cstmapp.png" />
            </div>
            <div className="appsev_pass">
              <h1>Custom App Development</h1>
              <p>Want an app built exactly for your needs? Our Custom App Development as a Service offers a complete solution. From design and coding to launch and beyond, we'll transform your vision into a powerful, user-friendly app that stands out in the market.</p>
            </div>
          </div>
        </div>
      </div>
      <div><Apptchstk/></div>
            <section id="app_prc_sec">
                <h1>Pricing</h1>
                <div id="app_prc_cntnr">
                    <div id="app_prc_cntnt"  className="app_basic">
                        <h1>Basic</h1>
                        {/* <h1>$500</h1> */}
                        <hr />
                        <h2>prototype</h2>
                        <Link to="/Connect">
                        <button class="btn">Get started</button>
                        </Link>
                    </div>
                    <div id="app_prc_cntnt" className="app_pro">
                        <h1>Pro</h1>
                        {/* <h1> $3000</h1> */}
                        <hr />
                        <h2>advanced app</h2>
                        <Link to="/Connect">
                        <button class="btn">Get started</button>
                        </Link>
                    </div>
                    <div id="app_prc_cntnt" className="app_adv">
                        <h1>Advanced</h1>
                        {/* <h1>$1500</h1> */}
                        <hr />
                        <h2>intermediate app</h2>
                        <Link to="/Connect">
                        <button class="btn">Get started</button>
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    )
}