import React from 'react';
import Appcarus from'./Appcarus.jsx';
import './Apptchstk.css';
const Apptech_stack = () => {
  return (
   <div id="apptech_stk_cntnr">
    <div id="app_sec4">
<div id="app_debts">
 <div id="apptechstk_hd"><h1>Technology stack</h1></div>
 <div id="appcrs_cntnr">
 <Appcarus/>
 </div>
 </div>
 </div>
 </div>
  )
}

export default Apptech_stack