import React from 'react';
import Datacarus from'./Datacarus.jsx';
import './Datatchstk.css';
const Datastk = () => {
  return (
   <div id="dttech_stk_cntnr">
    <div id="dt_sec4">
<div id="dt_debts">
 <div id="dttechstk_hd"><h1>Technology stack</h1></div>
 <div id="dtcrs_cntnr">
 <Datacarus/>
 </div>
 </div>
 </div>
 </div>
  )
}

export default Datastk