import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  let dropdownTimeout;

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleDropdownEnter = () => {
    clearTimeout(dropdownTimeout);
    setDropdownVisible(true);
  };

  const handleDropdownLeave = () => {
    dropdownTimeout = setTimeout(() => {
      setDropdownVisible(false);
    }, 300);
  };

  const navigate = useNavigate();

  const navigateToConnect = () => {
    navigate('/Connect');
  };

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <nav className={`navbar ${showNavbar ? '' : 'hidden'}`}>
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img src="./luxlogo.png" alt="Luxgenic Logo" />
        </Link>
        <div
          className={`menu-icon ${isMenuOpen ? 'open' : ''}`}
          onClick={handleMenuToggle}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div id="navi_links">
          <ul className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={handleMenuToggle}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/About" className="nav-links" onClick={handleMenuToggle}>
                About
              </Link>
            </li>
            <li
              className="nav-item"
              onMouseEnter={handleDropdownEnter}
              onMouseLeave={handleDropdownLeave}
            >
              <Link
                to="/Services_page"
                className="nav-links"
                onClick={handleMenuToggle}
              >
                Services
              </Link>
              {isDropdownVisible && (
                <div
                  className="dropdown_wrapper"
                  onMouseEnter={handleDropdownEnter}
                  onMouseLeave={handleDropdownLeave}
                >
                  <ul className="dropdown_blk">
                    <li>
                      <Link style={{ textDecoration: 'none', color: '#fff' }} to="/Web">
                        Web Development
                      </Link>
                    </li>
                    <li>
                      <Link style={{ textDecoration: 'none', color: '#fff' }} to="/Ui">
                        UI/UX Design
                      </Link>
                    </li>
                    <li>
                      <Link style={{ textDecoration: 'none', color: '#fff' }} to="/Appserv">
                        App Development
                      </Link>
                    </li>
                    <li>
                      <Link style={{ textDecoration: 'none', color: '#fff' }} to="/Digital_marketing">
                        Digital Marketing
                      </Link>
                    </li>
                    <li>
                      <Link style={{ textDecoration: 'none', color: '#fff' }} to="/Datamng">
                        Data Management
                      </Link>
                    </li>
                    <li>
                      <Link style={{ textDecoration: 'none', color: '#fff' }} to="/Cloud">
                        Cloud Services
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li className="nav-item">
              <Link to="/Project" className="nav-links" onClick={handleMenuToggle}>
                Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/Mvp" className="nav-links" onClick={handleMenuToggle}>
                Mvp
              </Link>
            </li>
            <li className="nav-item">
              <button className="cnct_button" role="button" onClick={navigateToConnect}>
                <h6>connect</h6>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;


