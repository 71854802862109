import React from 'react';
import './Datacarus.scss'; // Make sure to import your CSS file

const emojis = [
    ["./serv_imgs/tech_stack/mysql.png", "My Sql", ""],
    ["./serv_imgs/tech_stack/Hadoop.png", "Hadoop"],
    ["./serv_imgs/tech_stack/terra.png", "Terraform", ""],
    ["./serv_imgs/tech_stack/aws.png", "Aws", ""],
    ["./serv_imgs/tech_stack/azure.png", "Azure", "U+2695"],
    ["./serv_imgs/tech_stack/devops.png", "Devops", "U+1F30D"],
    ["./serv_imgs/tech_stack/powerbi.png", "Power Bi", ""],
    ["./serv_imgs/tech_stack/cloud_sql.png", "Cloud Sql", "U+1F4C8"],
    ["./serv_imgs/tech_stack/automl.png", "Auto ml", "U+1F4C8"],
];

const Wrapper = () => {
  const isImage = (src) => src.startsWith('./') || src.startsWith('http');

  return (
    <div id="cur_wrapper">
      <div className="carousel">
        {emojis.map((item, index) => (
          <div key={index} className="carousel__item">
            <div className="carousel__item-head">
              {isImage(item[0]) ? (
                <img src={item[0]} alt={item[2]} className="carousel-image" />
              ) : (
                <span className="emoji">{item[0]}</span>
              )}
            </div>
            <div className="carousel__item-body">
              <p className="title">{item[1]}</p>
              <p>Unicode: {item[2]}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Wrapper;
