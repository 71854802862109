import React, { useEffect } from 'react';
import '../pg_css/Mvp.css';
import Rotcrd from '../component/Rotcrd';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

const Mvp = () => {
  useEffect(() => {
    AOS.init({ // Initialize AOS
      duration: 1000, // Animation duration
      once: true, // Whether animation should only happen once
      offset: 200 // Offset (in px) from the original trigger point
    });
  }, []);
  return (
    <div id="mvp_wrap" >
      <section id="mvp_hr_sec">
        <div id="mvp_hero">
          <div id="mvp_title">
            <h1>Luxgenic Presents </h1>
          </div>
          <div id="mvp_vid_cntnr">
            <video src="./mvp_icons/mvp_video.mp4" autoPlay type="video/mp4" id="mvp_vid"></video>
          </div>
        </div>
      </section>
      <div id="abt_mvp" data-aos="fade-up"><div id="abt_mvp_img"><img src="./mvp_icons/mvp-icon1.png" /></div><h1>Minimum Viable Product</h1><p>MVP stands for Minimum Viable Product. It’s a
        core concept within the Lean Startup
        methodology, focusing on launching a product
        with just enough features to be usable by early
        adopters. MVP as a Service (MVPaaS) refers to a service
        offered by companies specializing in helping
        businesses develop and launch Minimum Viable
        Products (MVPs). These companies provide the
        expertise and resources to take your product
        concept from idea to reality, focusing on the core
        functionalities needed for validation and user
        feedback.</p></div>
      <div><Rotcrd /></div>
      <section id="paas" data-aos="fade-up">
        <h1>Product as a service</h1>
        <div id="pass_cntnr">
          <div className="pass_cntnt"><img src="./mvp_icons/mvp-icon2.png" /><h3>Stratergic Narrative </h3></div>
          <div className="pass_cntnt"><img src="./mvp_icons/mvp-icon3.png" /><h3>Visual Story Telling Blueprinting</h3></div>
          <div className="pass_cntnt"><img src="./mvp_icons/mvp-icon4.png" /><h3>High-Impact Film Production</h3></div>
          <div className="pass_cntnt"><img src="./mvp_icons/mvp-icon5.png" /><h3>Compelling Content Editing</h3></div>
          <div className="pass_cntnt"><img src="./mvp_icons/mvp-icon6.png" /><h3>Collaborative Brand Messaging</h3></div>
          <div className="pass_cntnt"><img src="./mvp_icons/mvp-icon7.png" /><h3>Streamlined Project Management</h3></div>
          <div className="pass_cntnt"><img src="./mvp_icons/mvp-icon8.png" /><h3>Engaging Motion Graphics Integration</h3></div>
        </div>
      </section>
      <section id="Race_io_intro"data-aos="fade-up">
        <h1>RACE.IO</h1>
        <div id="rc_cntnr">
          <div class="rc_cntnt">
            <h1>Ignite your Ideas</h1>
            <p>Welcome to Race.io, the premier platform for student innovators and entrepreneurs! At Race.io, we believe that great ideas can come from anyone, anywhere. Our mission is to provide students with the opportunity to pitch their ideas, receive expert feedback, and turn their visions into reality. Whether you have a groundbreaking tech innovation, a unique business concept, or a creative project, Race.io is here to help you accelerate your journey from idea to impact.</p>
          </div>
          <div class="rc_cntnt">
            <h1>How Race.io Works</h1>
            <p class='rc_cntnt2_para'>
              Race.io is designed to support every step of your entrepreneurial journey: Submit Your Idea – Have a brilliant idea? Submit your pitch to Race.io. We welcome ideas from all fields and disciplines. Pitch Events – Participate in our pitch events, where you can present your idea to a panel of experts, investors, and mentors. Feedback and Mentorship – Receive constructive feedback and guidance from experienced professionals to refine and enhance your pitch. Workshops and Resources – Access a variety of workshops, resources, and tools designed to help you develop your business plan, marketing strategy, and more. Funding Opportunities – Compete for funding to bring your idea to life. Successful pitches may receive grants, seed funding, or investment opportunities. Launch and Scale – Get support in launching your project and scaling it for success. Race.io provides ongoing mentorship and networking opportunities to ensure your venture thrives.
            </p>
          </div>
          <div class="rc_cntnt">
            <h1>Success Stories</h1>
            <p>
              Get inspired by these incredible success stories from Race.io participants: EcoTech Solutions – A group of students developed a revolutionary recycling technology and secured $50,000 in seed funding at our Sustainable Solutions Pitch Night. MedInnovate – This healthcare startup, focused on AI-driven diagnostics, attracted the attention of major investors and is now partnering with hospitals nationwide. ArtistryHub – A creative platform for independent artists, launched successfully after receiving expert mentorship and winning the Creative Ventures Pitch Day. These stories are a testament to the potential of student innovation. At Race.io, we are committed to turning your dreams into reality.
            </p>
          </div>
          <div class="rc_cntnt">
            <h1>Community and Networking</h1>
            <p>
              At Race.io, we believe in the power of community. Join a vibrant network of like-minded students, mentors, and industry experts: Forums and Discussion Boards – Connect with fellow innovators, share your experiences, and collaborate on ideas. Networking Events – Attend exclusive networking events to meet potential partners, investors, and mentors. Online Communities – Join our social media groups and online forums to stay engaged and inspired. Our community is here to support you every step of the way.
            </p>
          </div>
          <div class="rc_cntnt">
            <h1>Join the Race.io Community</h1>
            <p>
              At Race.io, we believe in the power of community. Join a vibrant network of like-minded students, mentors, and industry experts: Forums and Discussion Boards – Connect with fellow innovators, share your experiences, and collaborate on ideas. Networking Events – Attend exclusive networking events to meet potential partners, investors, and mentors. Online Communities – Join our social media groups and online forums to stay engaged and inspired. Our community is here to support you every step of the way.
            </p>
          </div>
          <div class="rc_cntnt">
            <h1>Resources and Workshops for Success</h1>
            <p>
              Enhance your entrepreneurial skills with our extensive resources and workshops: Business Plan Development – Learn how to create a solid business plan that attracts investors. Pitch Preparation – Get tips and strategies for delivering a compelling pitch. Marketing and Branding – Discover how to effectively market your idea and build a strong brand. Funding and Investment – Understand the funding landscape and how to secure financial support for your project. Race.io provides you with the tools and knowledge needed to succeed.
            </p>
          </div>
          <div class="rc_cntnt">
            <h1>About Race.io</h1>
            <p>
              Race.io was founded with the vision of empowering student entrepreneurs. Our team comprises passionate individuals dedicated to fostering innovation and supporting the next generation of leaders. We provide a platform where ideas are nurtured, developed, and transformed into successful ventures. Join us and be part of a movement that champions creativity, innovation, and entrepreneurship.
            </p>
          </div>
          <div class="rc_cntnt">
            <h1>Get in Touch with Race.io</h1>
            <p>
              Have questions, suggestions, or feedback? We’d love to hear from you! Reach out to us via email or follow us on social media. Your input helps us improve and provide the best content possible. Let’s connect and work together to bring your ideas to life.
            </p>
          </div>
        </div>
      </section>

      <section id="race_io" data-aos="fade-up">
        <h1>RACE.IO</h1>
        <div id="rc_crds">
          <div id="rc_crd">
            <div className="rc_crd_hdr">
              <img src="./mvp_icons/mvp-icon9.png" />
              <h3>01</h3>
            </div>
            <div className='rc_crd_cntnt'>
              <h2>The Innovation Bandwagon</h2>
              <p> Get your name in the mix by signing up for Race.io on our website. It's as easy as pie - just fill out a quick form with your name and email, and you're officially on board the innovation train</p>
            </div>
          </div>
          <div id="rc_crd">
            <div className="rc_crd_hdr">
              <img src="./mvp_icons/mvp-icon10.png" />
              <h3>02</h3>
            </div>
            <div className='rc_crd_cntnt'>
              <h2>Pitch your idea</h2>
              <p>Got an dea that's weirder than a three-headed monkey? Perfect! Head to our submission page and spill the beans about your wild creation. Don't hold back - the crazier, the better!</p>
            </div>
          </div>
          <div id="rc_crd">
            <div className="rc_crd_hdr">
              <img src="./mvp_icons/mvp-icon11.png" />
              <h3>03</h3>
            </div>
            <div className='rc_crd_cntnt'>
              <h2>Let the judgement day begin</h2>
              <p>Once submissions close, it's judgment timel Our team of judges will put on their thinking caps and dive deep into the sea of wacky ideas. Will yours float to the top like a unicorn on a rainbow? Only time will tell</p>
            </div>
          </div>
          <div id="rc_crd">
            <div className="rc_crd_hdr">
              <img src="./mvp_icons/mvp-icon12.png" />
              <h3>04</h3>
            </div>
            <div className='rc_crd_cntnt'>
              <h2>Cue the confettie</h2>
              <p>Drumroll, please The winners of Race.io are about to be revealed, and it could be you! Keep an eye on your inbox for that golden ticket notification. And don't forget to do a victory dance - you've earned it</p>
            </div>
          </div>
        </div>
        <div id="rc_crd" className="rc_crd5" style={{ margin: '50px auto' }}>
          <div className="rc_crd_hdr">
            <img src="./mvp_icons/mvp-icon13.png" />
            <h3>05</h3>
          </div>
          <div className='rc_crd_cntnt'>
            <h2>Claim Your price party on</h2>
            <p>If you're one of the lucky winners, it's time to claim your prize and bask in the glory of your triumph.
              Get Seed funding, mentorship opportunities, and a chance to join our team!</p>
          </div>
        </div>
      </section>
      <section id="mvp_prc_sec" data-aos="fade-up">
                <h1>Pricing</h1>
                <div id="mvp_prc_cntnr">
                    <div id="mvp_prc_cntnt">
                        <h1>Basic</h1>
                        {/* <h1> $400 </h1> */}
                        <hr />
                        <h2>consultation</h2>
                        <Link to="/Connect">
                        <button class="btn">Get started</button>
                        </Link>
                    </div>
                    <div id="mvp_prc_cntnt" className="cld_pro">
                        <h1>Pro</h1>
                        {/* <h1>$2000 </h1> */}
                        <hr />
                        <h2>full development and support</h2>
                        <Link to="/Connect">
                        <button class="btn">Get started</button>
                        </Link>
                    </div>
                    <div id="mvp_prc_cntnt">
                        <h1>Advanced</h1>
                        {/* <h1>$1000</h1> */}
                        <hr />
                        <h2>build and testing</h2>
                        <Link to="/Connect">
                        <button class="btn">Get started</button>
                        </Link>
                    </div>
                </div>
            </section>
    </div>
  )
}

export default Mvp
