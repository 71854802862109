import React, { useState, useEffect } from 'react';
import './Dgtlslider.css';

const Dgtlslider = () => {
  const [images, setImages] = useState([
    './serv_imgs/dgtl_imgs/dgt1.jpg',
    './serv_imgs/dgtl_imgs/dgt2.jpg',
  ]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    },21000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="dgtlslider-container">
      <div className="button-container">
      <button onClick={prevSlide} className="arrow-button">
          <img src='./serv_imgs/serv_icons/slider1.png' alt="Previous Slide" className="arrow-icon1" />
        </button>
        <button onClick={nextSlide} className="arrow-button">
          <img src='./serv_imgs/serv_icons/slider2.png' alt="Next Slide" className="arrow-icon2" />
        </button>
      </div>
      <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
    </div>
  );
};

export default Dgtlslider;