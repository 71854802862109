import react from 'react';
import './ui.css';
import Uislider from './Uislider';
import Uitechstk from './Uitechstk';
import { Link } from 'react-router-dom';

export default function ui() {
  return (
    <div id="ui_ux_wrapper">
      <div><Uislider /></div>
      <div id="ui_services">
        <h1>What we provide?</h1>
        <div id="ui_services_cntnr">
          <div className="ui_services_cntnt">
            <div className="uisev_img">
              <img src="./serv_imgs/serv_icons/wireframe.png" />
            </div>
            <div className="uisev_pass">
              <h1>Wireframing and Prototyping:</h1>
              <p>We create low-fidelity wireframes and interactive prototypes to visualize and test user flows before investing in high-fidelity design.</p>
            </div>
          </div>
          <div className="ui_services_cntnt">
            <div className="uisev_img">
              <img src="./serv_imgs/serv_icons/painting.png" />
            </div>
            <div className="uisev_pass">
              <h1>Visual Design & Branding</h1>
              <p> Our design experts create a visually compelling user interface that aligns with your brand identity and resonates with your target audience
              </p>
            </div>
          </div>
          <div className="ui_services_cntnt">
            <div className="uisev_img">
              <img src="./serv_imgs/serv_icons/interaction.png" />
            </div>
            <div className="uisev_pass">
              <h1>Microinteractions & Animations</h1>
              <p>Interaction design focuses on creating intuitive and engaging interfaces, enhancing user experience through thoughtful design of interactive elements, and ensuring seamless and efficient user interaction with digital products.</p>
            </div>
          </div>
          <div className="ui_services_cntnt">
            <div className="uisev_img">
              <img src="./serv_imgs/serv_icons/access.png" />
            </div>
            <div className="uisev_pass">
              <h1>Accessibility Design</h1>
              <p>We ensure your website or app is accessible to users with disabilities, adhering to WCAG (Web Content Accessibility Guidelines) standards</p>
            </div>
          </div>
        </div>
      </div>
      <div><Uitechstk/></div>
      <section id="ui_prc_sec">
        <h1>Pricing</h1>
        <div id="ui_prc_cntnr">
          <div id="ui_prc_cntnt" className='ui_basic'>
            <h1>Basic</h1>
            {/* <h1>$300</h1> */}
            <hr />
            <h2>up to 10 screens</h2>
            <Link to="/Connect">
            <button class="btn">Get started</button>
            </Link>
          </div>
          <div id="ui_prc_cntnt" className='ui_pro'>
            <h1>Pro</h1>
            {/* <h1>$1200</h1> */}
            <hr />
            <h2>unlimited screens</h2>
            <Link to="/Connect">
            <button class="btn">Get started</button>
            </Link>
          </div>
          <div id="ui_prc_cntnt" className='ui_adv'>
            <h1>Advanced</h1>
            {/* <h1>$700</h1> */}
            <hr />
            <h2>up to 25 screens</h2>
            <Link to="/Connect">
            <button class="btn">Get started</button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}