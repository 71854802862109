import react from 'react';
import './Digital_marketing.css';
import Dgtstk from './Dgtstk.jsx';
import Dgtslider from './Dgtlslider.jsx';
import { Link } from 'react-router-dom';

export default function digitalmarketing() {
  return (
    <div id="dgtlmrkt_wrap">
      {/* <section id="dgtlmrkt_hero">
        <div id="dgtlmrkt_cntnr">
          <h1>The Loud Voice &nbsp; &nbsp; &nbsp; &nbsp;of your Brand</h1>
          <img src="https://img.freepik.com/premium-photo/digital-marketing-isometric-artwork-where-people-are-working-promote-brand-social-media-plat_1029469-38414.jpg" />
        </div>
      </section> */}
      <div id="sldr_hr"><Dgtslider /></div>
      {/* <div id="dgt_mrq" style={{marqueeSpeed:" 20s",direction:"scrollLeft", role:"marquee"}}>
        <div id="dgt_mrq_cnt">
          <h1>SEO</h1>
          <h1>Branding</h1>
          <h1>Marketing</h1>
          <h1>Email Marketing</h1>
        </div>
      </div> */}
      <div id="dgtl_services">
        <h1>What we provide?</h1>
        <div id="dgtl_services_cntnr">
          <div className="dgtl_services_cntnt">
            <div className="dgsev_img">
              <img src="./serv_imgs/serv_icons/pay_per.png" />
            </div>
            <div className="dgsev_pass">
              <h1>Pay-Per-Click Advertising</h1>
              <p>We create and manage targeted PPC campaigns on platforms like Google Ads and Bing Ads to drive qualified traffic to your website.
Remarketing & Retargeting: Reconnect with website visitors who have shown interest in your products or services, increasing conversion rates</p>
            </div>
          </div>
          <div className="dgtl_services_cntnt">
            <div className="dgsev_img">
              <img src="./serv_imgs/serv_icons/content-marketing.png" />
            </div>
            <div className="dgsev_pass">
              <h1>Content Marketing</h1>
              <p>We develop a data-driven content strategy that creates valuable and engaging content to attract and nurture your target audience
              </p>
            </div>
          </div>
          <div className="dgtl_services_cntnt">
            <div className="dgsev_img">
              <img src="./serv_imgs/serv_icons/seo.png" />
            </div>
            <div className="dgsev_pass">
              <h1>Search Engine Optimization</h1>
              <p> We identify relevant keywords with high search volume and low competition to optimize your website content and ranking in search engines.
               </p>
            </div>
          </div>
          <div className="dgtl_services_cntnt">
            <div className="dgsev_img">
              <img src="./serv_imgs/serv_icons/social-marketing.png" />
            </div>
            <div className="dgsev_pass">
              <h1>Social Media Marketing</h1>
              <p>We develop a social media strategy tailored to your target audience and platforms, and manage your social media presence to foster engagement and brand loyalty.</p>
            </div>
          </div>
          <div className="dgtl_services_cntnt">
            <div className="dgsev_img">
              <img src="./serv_imgs/serv_icons/email.png" />
            </div>
            <div className="dgsev_pass">
              <h1>Email Marketing</h1>
              <p>We build targeted email lists and segment them for personalized campaigns, design engaging emails, set up automated sequences to nurture leads and improve engagement, and track key metrics to provide insightful reports for measuring success and identifying improvement areas</p>
            </div>
          </div>
        </div>
      </div>
      <div><Dgtstk/></div>
      <section id="dgt_prc_sec">
        <h1>Pricing</h1>
        <div id="dgt_prc_cntnr">
          <div id="dgt_prc_cntnt"  className='dgt_basic'>
            <h1>Basic</h1>
            {/* <h1>$1,000/m</h1> */}
            <hr />
            <p>SEO, Google My Business, 2 blogs/month, social media for 2 platforms.</p>
            <Link to="/Connect">
            <button class="btn">Get started</button>
            </Link>
          </div>
          <div id="dgt_prc_cntnt" className='dgt_pro'>
            <h1>Pro</h1>
            {/* <h2>$5,000/m</h2> */}
            <hr />
            <p>Content, social ads, email automation, landing pages, monitoring, ROI.</p>
            <Link to="/Connect">
            <button class="btn">Get started</button>
            </Link>
          </div>
          <div id="dgt_prc_cntnt"  className='dgt_adv'>
            <h1>Advanced</h1>
            {/* <h1> $2,500/m</h1> */}
            <hr />
            <p>SEO, link building, 4 blogs/month, social ads, 1 email campaign, CRO, monthly reports.</p>
            <Link to="/Connect">
            <button class="btn">Get started</button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}