import React from 'react';
import './Slicksld.css'; // Make sure to import your CSS file

const Slider = () => {
 

  return (
    <div className="slider">
      <div className="slide-track">
        {/* <div className="slide">
           <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png" height="100" width="250" alt="" /> 
          <h1 style={{fontSize:'54px'}}>Creativity</h1>
        </div> */}
        <div className="slide">
          <img src="image1.jpeg" height="400" width="400" alt="" />
        </div>
        <div className="slide">
          <img src="image2.jpeg" height="400" width="400" alt="" />
        </div>
        <div className="slide">
          <img src="image3.jpeg" height="400" width="400" alt="" />
        </div>
        <div className="slide">
          <img src="image4.jpeg" height="400" width="400" alt="" />
        </div>
        <div className="slide">
          <img src="image5.jpeg" height="400" width="400" alt="" />
        </div>
        <div className="slide">
        <img src="image1.jpeg" height="400" width="400" alt="" />
        </div>
        <div className="slide">
        <img src="image2.jpeg" height="400" width="400" alt="" />
        </div>
        {/* <div className="slide">
          <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png" height="100" width="250" alt="" />
        </div>
        <div className="slide">
          <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png" height="100" width="250" alt="" />
        </div>
        <div className="slide">
          <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png" height="100" width="250" alt="" />
        </div>
        <div className="slide">
          <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png" height="100" width="250" alt="" />
        </div>
        <div className="slide">
          <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png" height="100" width="250" alt="" />
        </div>
        <div className="slide">
          <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png" height="100" width="250" alt="" />
        </div> */}
      </div>
    </div>
  );
}

export default Slider;
