import React from 'react';
import Ourwrk from '../Ourwrk.jsx';


const Project=()=>{
  return (
    <section>
    <Ourwrk/>
    </section>
  );
}
export default Project;
