import React from 'react';
import './Rotcrd.css';

const HexagonPattern = () => {
  const cards = [
    { description: "1.BrainStorming and Idea Generation" },
    { description: "2.Define and Refine" },
    {  description: "3.Design and Business Analysis" },
    { description: "4.Start of development" },
    {  description: "5.End of development" },
    { description: "6.Testing, Launch and feedback" },
  ];

  return (
    <div className="void" id="void">
      <div className="crop">
        <ul id="rotcardul" style={{ '--count': cards.length }}>
          {cards.map((card, index) => (
            <li className="rotcardli" key={index}>
              <div className="card">
                <a href="#">
                  <span className="model-name">{card.modelName}</span>
                  <span>{card.description}</span>
                </a>
              </div>
            </li>
          ))}
        </ul>
        <div className="last-hexagon"></div>
        <div className="second-hexagon"></div>
      </div>
      <div className="mask"></div>
      <div className="center-hexagon">
        <h1>MVP</h1>
        <h5>Work flow</h5>
      </div>
    </div>
  );
};

export default HexagonPattern;

