import React, { useEffect } from 'react';
import './Timeline.css';

export default function Timeline() {
    useEffect(() => {
        const handleScroll = () => {
            const progressBar = document.getElementById('progress-bar');
            const timelineContainer = document.getElementById('timeline_cntnr');
            if (progressBar && timelineContainer) {
                const containerHeight = timelineContainer.scrollHeight - window.innerHeight;
                const scrollTop = window.scrollY || window.pageYOffset;
                const scrolled = (scrollTop / containerHeight) * timelineContainer.scrollHeight;
                progressBar.style.height = `${scrolled}px`;
            }
        };

        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <div id="timeline_cntnr">
                <h1>OUR JOURNEY</h1>
                <div className="timeline">
                    <div id="progress-bar"></div> {/* Ensure the progress bar is present in the DOM */}
                    <ul>
                        <li>
                            <div className="right_content">
                                <h2> A Dream Takes Flight</h2>
                                <p>In the heart of Chennai, India, Lx was born. Fueled by ambition and by a passion for innovation, we set out to make our mark on the IT landscape, one solution at a time</p>
                            </div>
                            <div className="left_content">
                                <h3>January 2019 </h3>
                            </div>
                        </li>
                        <li>
                            <div className="right_content">
                                <h2>Growth and Collaboration</h2>
                                <p>As our reputation grew, so did our team and our reach. We forged strategic partnerships with like-minded organizations and expanded our service offerings to meet the diverse needs of our clients.</p>
                            </div>
                            <div className="left_content">
                                <h3>February 2020</h3>
                            </div>
                        </li>
                        <li>
                            <div className="right_content">
                                <h2>Recognition and Reinvention</h2>
                                <p>In our third year, our hard work paid off as we were recognized for our commitment to excellence. But we didn't rest on our laurels. Instead, we pushed forward, embracing new technologies and reinventing ourselves for the digital age.</p>
                            </div>
                            <div className="left_content">
                                <h3>March 2021</h3>
                            </div>
                        </li>
                        <li>
                            <div className="right_content">
                                <h2>Trailblazing Innovation</h2>
                                <p>With our sights set on the future, we dove headfirst into emerging technologies, from AI and machine learning to blockchain and IoT. Our innovative solutions helped our clients stay ahead of the curve and navigate the complexities of the digital landscape.</p>
                            </div>
                            <div className="left_content">
                                <h3>April 2022</h3>
                            </div>
                        </li>
                        <li>
                            <div className="right_content">
                                <h2>Expansion and Exploration</h2>
                                <p>Armed with a spirit of adventure, we expanded our horizons, reaching new markets and new heights. Our global footprint grew, but our commitment to our clients remained steadfast, guiding every decision and every innovation.</p>
                            </div>
                            <div className="left_content">
                                <h3>May 2023</h3>
                            </div>
                        </li>
                        <li>
                            <div className="right_content">
                                <h2> Celebrating Five Years of Success</h2>
                                <p>Today, as we celebrate our fifth anniversary, we reflect on how far we've come and look forward to the journey ahead. With gratitude for our team, our clients, and our community, we recommit ourselves to our mission: to empower businesses to thrive in the digital age.</p>
                            </div>
                            <div className="left_content">
                                <h3>April 2024</h3>
                            </div>
                        </li>
                        {/* <li>
                            <div className="right_content">
                                <h2>Sample Text</h2>
                                <p>A paragraph is defined as “a group of sentences or a single sentence that forms a unit” (Lunsford and Connors 116). Length and appearance do not determine whether a section in a paper is a paragraph. For instance, in some styles of writing, particularly journalistic styles, a paragraph can be just one sentence long.</p>
                            </div>
                            <div className="left_content">
                                <h3>July 2019 </h3>
                            </div>
                        </li>
                        <li>
                            <div className="right_content">
                                <h2>Sample Text</h2>
                                <p>A paragraph is defined as “a group of sentences or a single sentence that forms a unit” (Lunsford and Connors 116). Length and appearance do not determine whether a section in a paper is a paragraph. For instance, in some styles of writing, particularly journalistic styles, a paragraph can be just one sentence long.</p>
                            </div>
                            <div className="left_content">
                                <h3>August 2019 </h3>
                            </div>
                        </li> */}
                        <div style={{clear:'both'}}></div>
                    </ul>
                </div>
            </div>
        </>
    )
}
