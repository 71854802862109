import React, { useState } from "react";
import './Abt.css'; // Import CSS file for styling
import 'bootstrap/dist/css/bootstrap.min.css';
import Timeline from './Timeline';

const Abt = () => {
    return (
        <div id="abt_wrapper">
            {/* <div id="abt_bgvid"><video src="./abtbgvid.mp4" muted autoPlay loop type="video/mp4" id="abtbg_video"></video> */}
            <section id="abt_hero" >
                <div id="abt_hero_title">
                    <h1>Discover Our Purpose and Our Impact on the IT Industry</h1>
                </div>
            </section>
            {/* <section id="abt_us_sec">
            <div id="abt_us_cntnr">
            <div id="abt_us_img">
            <img src="https://image.freepik.com/free-photo/group-young-professionals-working-together_23-2148499818.jpg" class="top-image" width={800}/>
           </div>
            <div id="abt_us_cntnt">
                <h1>About Us</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, aliquam modi nobis illo totam repellat adipisci! Nobis quam obcaecati eligendi id reiciendis sunt rerum ratione quidem optio suscipit, excepturi veritatis.</p>
            </div>
            </div>
        </section> */}
            <div id="time_ln"><Timeline /></div>
            <div id="lux_ext"><img src="Luxgenic_ext.gif" id="ext_img" /></div>
            <section id="msn_cntnr">
                <div id="gns_hdr"><h1>Genesis and Vision</h1></div>
                <div id="msn_div">
                    <div id="msn_cntnt" className="msn_cntnt1">
                        <img src="./gn_ethos.png" className="msn_img" />
                        <h2>Inception and Ethos</h2>
                        <p>Luxgenic Infotech LLP,
                            established in 2019 , emerged
                            as a beacon of innovation in
                            the realm of IT solutions. Our
                            founding ethos revolves
                            around leveraging technology
                            to empower businesses and
                            individuals, fostering a digital
                            ecosystem that thrives on
                            excellence and integrity</p>
                    </div>
                    <div id="msn_cntnt" className="msn_cntnt2">
                        <img src="./gn_client.png" className="msn_img" />
                        <h2>Client Centric Approach</h2>
                        <p>Within the tapestry of our
                            essence lie the sacred tenets of
                            agility, adaptability, and
                            innovation. We greet change as
                            a gate to transformation,
                            steadfast in our pursuit of the
                            celestial frontiers of
                            technological progress</p></div>
                    <div id="msn_cntnt" className="msn_cntnt3">
                        <img src="./gn_prncpl.png" className="msn_img" />
                        <h2>Founding Principles</h2>
                        <p>At Luxgenic, our odyssey is
                            illuminated by an unwavering
                            dedication to comprehending and
                            fulfilling our clients' desires. We
                            embrace the art of crafting
                            everlasting alliances that
                            transcend mere business
                            transactions, embodying our
                            steadfast pledge to client
                            contentment.
                        </p></div>
                </div>
            </section>
        </div>

    );
}

export default Abt;
