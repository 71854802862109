import React from 'react';
import './Uicarus.scss'; // Make sure to import your CSS file

const emojis = [
  ["./serv_imgs/tech_stack/html.png", "Html5", "E-commerce Image"],
  ["./serv_imgs/tech_stack/css.png", "css3"],
  ["./logo192.png", "React js"],
  ["./serv_imgs/tech_stack/adbill.png", "Adboe Illustration", ""],
  ["./serv_imgs/tech_stack/xd.png", "Adobe Xd", "U+2695"],
  ["./serv_imgs/tech_stack/figma.png", "figma", "U+1F30D"],
  ["./serv_imgs/tech_stack/invision.png", "invision", "U+1F4E1"],
  ["./serv_imgs/tech_stack/sketch.png", "Sketch", "U+1F4C8"],
  ["./serv_imgs/tech_stack/ps.png", "Photoshop", "U+1F4C8"],
];

const Wrapper = () => {
  const isImage = (src) => src.startsWith('./') || src.startsWith('http');

  return (
    <div id="cur_wrapper">
      <div className="carousel">
        {emojis.map((item, index) => (
          <div key={index} className="carousel__item">
            <div className="carousel__item-head">
              {isImage(item[0]) ? (
                <img src={item[0]} alt={item[2]} className="carousel-image" />
              ) : (
                <span className="emoji">{item[0]}</span>
              )}
            </div>
            <div className="carousel__item-body">
              <p className="title">{item[1]}</p>
              <p>Unicode: {item[2]}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Wrapper;
