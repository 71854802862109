import React, { useEffect, useRef } from 'react';
import './serv.css';
import Crdstack_interact from './crdstack_interact';
import { Link } from 'react-router-dom';
export default function Services() {
  return (
    <section id="serv_main">
      <div id="serv_intro"><video src="./serv_hero_video.mp4" autoPlay type="video/mp4" id="serv_hero_video"></video></div>
      <div id="scroll_down_indicator">
        <a class="scroll_icon" href="#services_hero_container"><img src="./scroll.png" /></a>
      </div>
     
        <div id="services_hero_container">
            <h1>Welcome to Lux City</h1>
        </div>

      <section id="services_cards"><Crdstack_interact /></section>
      <div id="serv_bgvid"><video src="./luxcty.mp4" autoPlay  type="video/mp4" id="bg_video"></video></div>

      <div id="price_sec">
        <h1>pricing</h1>
        <div id="prc_crd_cont">
          <div id="prc_crd1">
            <div id="prc_crd1_title">
             <h1>Basic</h1>
             <p>Ideal for startups</p>
             
            </div>
            <hr/>
            <div id="prc_crd2_price">
              {/* <h2>$499</h2>
              <p>per month</p>
              <h2>$5000</h2>
              <p>per year</p> */}
              <h5> Know more about our features</h5>
            </div>
            <Link to="/Connect">
            <div id="prc_crd1_btn">
              <button class="button-82-pushable" role="button">
                <span class="button-82-shadow"></span>
                <span class="button-82-edge"></span>
                <span class="button-82-front text">
                  Get Started
                </span>
              </button>
            </div>
          </Link>
          </div>
          <div id="prc_crd2">
            <div id="prc_crd2_title">
              <h1>Premium</h1>
              <p>For established businesses needing advanced, scalable IT solutions.</p>
            </div>
            <hr/>
            <div id="prc_crd2_price">
              {/* <h2>$2999</h2>
              <p>per month</p>
              <h2>$30,000</h2>
              <p>per year</p> */}
              <h5> Know more about our features</h5>
            </div>
            <Link to="/Connect">
            <div id="prc_crd2_btn">
              <button class="button-82-pushable" role="button">
                <span class="button-82-shadow"></span>
                <span class="button-82-edge"></span>
                <span class="button-82-front text">
                  Get Started
                </span>
              </button>
            </div>
            </Link>
          </div>
          <div id="prc_crd3">
          <div id="prc_crd3_title">
              <h1>Standard</h1>
              <p>For growing businesses needing IT solutions and support.</p>
            </div>
            <hr/>
            <div id="prc_crd3_price">
              {/* <h2>$1499</h2>
              <p>per month</p>
              <h2>$15,000</h2>
              <p>per year (Save $3000!)</p> */}
              <h5> Know more about our features</h5>
            </div>
            <Link to="/Connect">
            <div id="prc_crd3_btn">
              <button class="button-82-pushable" role="button">
                <span class="button-82-shadow"></span>
                <span class="button-82-edge"></span>
                <span class="button-82-front text">
                  Get Started
                </span>
              </button>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
