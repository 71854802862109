import React from 'react';
import './crdstack_interact.css'; // Import your CSS file
import { Link } from 'react-router-dom';

const CardStack = () => {
  return (
    <>
      <div id='intercrd_main'>
        <ul id="interact_cards">
          <li className="inter_card" id="card_1">
            <div className="card__content">
              <div>
                <h1>Web Development</h1>
                <p>We offer comprehensive web services tailored to your needs. Our strategic planning and consulting focus on understanding your audience and goals. With UX design thinking, we ensure smooth user journeys. Responsive web design adapts seamlessly across devices. From front-end to back-end development, we ensure robust functionality. Advanced features include custom development, API integrations, SEO, and analytics. Ongoing support ensures security, performance optimization, and content management.</p>
                <Link style={{ textDecoration: 'none', color: '#fff' }} to="/Web">
                  {/* <button className="button-29" role="button">Read more</button> */}
                  <button className="button-29">Read more</button>
                </Link>
              </div>
              <figure style={{ marginBottom: '0rem' }}>
                <img src="./servimg1.jpeg" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="inter_card" id="card_2">
            <div className="card__content">
              <div>
                <h2>UI/UX Designing</h2>
                <p>Our user research delves into audience needs and competitor analysis. We craft user personas and design clear information architecture. Visual design aligns with branding, while wireframing and prototyping aid in testing. We optimize user journeys and interactions, ensuring accessibility and delightful microinteractions. Services extend to wearables, AR/VR, front-end integration, and design thinking workshops.</p>
                <Link to="/Ui">
                  <button className="button-29">Read more</button>
                </Link>
              </div>
              <figure style={{ marginBottom: '0rem' }}>
                <img src="./servimg2.jpeg" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="inter_card" id="card_3">
            <div className="card__content">
              <div>
                <h2>App Development</h2>
                <p>We offer comprehensive solutions tailored to your app needs. Mobile app development encompasses native (iOS, Android), cross-platform (React Native, Flutter), and Progressive Web Apps (PWAs). Our full lifecycle services cover strategy, UI/UX design, development, QA/testing, ASO, and ongoing maintenance. Additional services include backend development, API integration, wearable app development, and AR/VR app development. We ensure your app stands out with top-notch performance, user experience, and functionality across platforms.</p>
                <Link to="/Appserv">
                  <button className="button-29" role="button">Read more</button>
                </Link>
              </div>
              <figure style={{ marginBottom: '0rem' }}>
                <img src="./servimg3.jpg" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="inter_card" id="card_4">
            <div className="card__content">
              <div>
                <h2>Data Management</h2>
                <p>We excel in data acquisition, offering ETL and ingestion pipelines for diverse sources, including IoT. Our storage solutions cover cloud, on-premises, and hybrid models, with expertise in data lake and warehouse management. Security and governance are paramount, with access control, encryption, and compliance services. We provide data processing and analytics, including cleansing, BI tools, and AI integration. Additional services include backup, archiving, and data monetization strategies.</p>
                <Link to="/Datamng">
                  <button className="button-29" role="button">Read more</button>
                </Link>
              </div>
              <figure style={{ marginBottom: '0rem' }}>
                <img src="./servimg4.jpg" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="inter_card" id="card_5">
            <div className="card__content">
              <div>
                <h2>Digital Marketing</h2>
                <p>
                  Elevate your online presence with our tailored digital marketing strategies. From SEO and SEM to content creation and social media engagement, we ensure effective outreach. Our services extend to email marketing, website optimization, and comprehensive analytics for maximum ROI.</p>
                <Link to="/Digital_marketing">
                  <button className="button-29" role="button">Read more</button>
                </Link>
              </div>
              <figure style={{ marginBottom: '0rem' }}>
                <img src="./servimg5.jpeg" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="inter_card" id="card_6">
            <div className="card__content">
              <div>
                <h2>Cloud services</h2>
                <p>Elevate your cloud operations with Infrastructure as a Service (IaaS) for virtual machines, storage, compute clusters, and networking. Platform as a Service (PaaS) simplifies app development, databases, containerization, and serverless computing. Software as a Service (SaaS) offers ready-to-use business apps, security, analytics, and content management. Additional services include managed solutions, disaster recovery, IoT, and AI/ML tools, ensuring comprehensive cloud support.</p>
                <Link to="/cloud">
                  <button className="button-29" role="button">Read more</button>
                </Link>
              </div>
              <figure style={{ marginBottom: '0rem' }}>
                <img src="./servimg6.jpg" alt="Image description" />
              </figure>
            </div>
          </li>

        </ul>
      </div>
    </>
  );
}

export default CardStack;
