import React from "react";
import './Footer.css';
import { Link } from "react-router-dom";

export default function(){
  return(
    <footer id="ftr">
      <div id="ftr_cntnr">
        <div id="ftr_logo"><img src="ftr_luxgenic.png"/></div>
        <div id="ftr_lnks">
          <ul id="ftr_nav">
            <li><Link to="./">Home</Link></li>
            <li><Link to="/About">About</Link></li>
            <li><Link to="/Services_page">Services</Link></li>
            <li><Link to="/Project">Projects</Link></li>
            <li><Link to="/Mvp">Mvp</Link></li>
          </ul>
        </div>
        <div>
        <ul className="ftr_scl_lnks">
              <li className="ftr-lnk">
                <a
                  href="https://www.facebook.com/p/Luxgenic-Infotech-LLP-100072229853707/?_rdr"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="facebook"
                  target="blank"
                >
                  <span className="ion-logo-instagram">
                    <img src="./facaicon.png" />
                  </span>
                </a>
              </li>
              <li className="ftr-lnk">
                <a
                  href="https://www.linkedin.com/company/luxgenicinfotech/about/?viewAsMember=true"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Linkedin"
                  target="blank"
                >
                  <span className="ion-logo-instagram">
                    <img src="./linaicon.png" />
                  </span>
                </a>
              </li>
              <li className="ftr-lnk">
                <a
                  href="mailto:akhilkasinolla@gmail.com"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Mail"
                  target="blank"
                >
                  <span className="mail">
                    <img src="./mailaicon.png" />
                  </span>
                </a>
              </li>
              <li className="ftr-lnk">
                <a
                  href="https://www.instagram.com/luxgenic/"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Instagram"
                  target="blank"
                >
                  <span className="ion-logo-instagram">
                    <img src="./instaicon.png"/>
                  </span>
                </a>
              </li>
            </ul>
        </div>
        <div id="cpy_rght">
        <p className="copyright">
              {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
              Copyright © All rights reserved |{" "}
              <a href="https://luxgenicinfotech.com/" target="_blank">
                Luxgenic Infotech LLP
              </a>
       </p>
       </div>
      </div>
    </footer>
  )
}
