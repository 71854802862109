// import React, { useState,useRef } from 'react';
// import './Connect.css';
// import emailjs from '@emailjs/browser';


// const ContactForm = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     message: ''
//   });

//   const [errors, setErrors] = useState({});

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const errors = validateForm(formData);
//     if (Object.keys(errors).length === 0) {
//       console.log('Form submitted:', formData);
//       window.alert('Form successfully submitted');
//       setErrors({});
//       setFormData({
//         name: '',
//         email: '',
//         phone: '',
//         message: ''
//       });
//     } else {
//       setErrors(errors);
//     }
//   };

//   const validateForm = (data) => {
//     const errors = {};
//     if (!data.name.trim()) {
//       errors.name = 'Name is required';
//     }
//     if (!data.email.trim()) {
//       errors.email = 'Email is required';
//     } else if (!isValidEmail(data.email)) {
//       errors.email = 'Invalid email format';
//     }
//     if (!data.phone.trim()) {
//       errors.phone = 'Phone number is required';
//     } else if (!isValidPhone(data.phone)) {
//       errors.phone = 'Invalid phone number';
//     }
//     if (!data.message.trim()) {
//       errors.message = 'Message is required';
//     }
//     return errors;
//   };

//   const isValidEmail = (email) => {
//     return /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(email);
//   };

//   const isValidPhone = (phone) => {
//     return /^[0-9]{10}$/.test(phone);
//   };
//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs
//       .sendForm('service_2rfbauv', 'template_29vhd8p', form.current, {
//         publicKey: 'f1Jp2rgMLEcGrCvKw',
//       })
//       .then(
//         () => {
//           console.log('SUCCESS!');
//         },
//         (error) => {
//           console.log('FAILED...', error.text);
//         },
//       );
//   };
//   return (
//     <form ref={form} onSubmit={sendEmail}>
//     <section id="form_wrapper">
//       <div className="form_container">
//         <div className="row input-container">
//           <div id="form_header">
//             <h5>Get in Touch</h5>
//             <h1>Let's collaborate, Reach Out to us</h1>
//             <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint sapiente voluptas odio eaque sunt veritatis?</p>
//           </div>
//           <div className="col-xs-12">
//             <div className="styled-input wide">
//               <input
//                 type="text"
//                 name="from_name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 required
//               />
//               <label>Name</label>
//               {errors.name && <span className="error">{errors.name}</span>}
//             </div>
//           </div>
//           <div className="col-md-6 col-sm-12">
//             <div className="styled-input">
//               <input
//                 type="email"
//                 name="from_email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//               />
//               <label>Email</label>
//               {errors.email && <span className="error">{errors.email}</span>}
//             </div>
//           </div>
//           <div className="col-md-6 col-sm-12">
//             <div className="styled-input">
//               <input
//                 type="text"
//                 name="phone"
//                 value={formData.phone}
//                 onChange={handleChange}
//                 required
//               />
//               <label>Phone Number</label>
//               {errors.phone && <span className="error">{errors.phone}</span>}
//             </div>
//           </div>
//           <div className="col-xs-12">
//             <div className="styled-input wide">
//               <textarea
//                 name="message"
//                 value={formData.message}
//                 onChange={handleChange}
//                 required
//               ></textarea>
//               <label>Message</label>
//               {errors.message && <span className="error">{errors.message}</span>}
//             </div>
//           </div>
//           <div className="col-xs-12 d-flex justify-content-center">
//             <button className="submit-btn" onClick={handleSubmit}>
//               <img src="./paperpln.png" width={50} className='paper-plane' alt="Paper Plane Icon"/>
//             </button>
//           </div>
//         </div>
//       </div>
//     </section>
//     </form>
//   );
// };

// export default ContactForm;
// import React, { useState, useRef } from 'react';
// import './Connect.css';
// import emailjs from '@emailjs/browser';

// const ContactForm = () => {
//   const [formData, setFormData] = useState({
//     form_name: '',
//     form_email: '',
//     phone: '',
//     message: ''
//   });

//   const [errors, setErrors] = useState({});
//   const form = useRef();

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const errors = validateForm(formData);
//     if (Object.keys(errors).length === 0) {
//       console.log('Form submitted:', formData);
//       window.alert('Form successfully submitted');
//       setErrors({});
//       setFormData({
//         form_name: '',
//         form_email: '',
//         phone: '',
//         message: ''
//       });
//     } else {
//       setErrors(errors);
//     }
//   };

//   const validateForm = (data) => {
//     const errors = {};
//     if (!data.form_name.trim()) {
//       errors.form_name = 'Name is required';
//     }
//     if (!data.form_email.trim()) {
//       errors.form_email = 'Email is required';
//     } else if (!isValidEmail(data.form_email)) {
//       errors.form_email = 'Invalid email format';
//     }
//     if (!data.phone.trim()) {
//       errors.phone = 'Phone number is required';
//     } else if (!isValidPhone(data.phone)) {
//       errors.phone = 'Invalid phone number';
//     }
//     if (!data.message.trim()) {
//       errors.message = 'Message is required';
//     }
//     return errors;
//   };

//   const isValidEmail = (email) => {
//     return /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(email);
//   };

//   const isValidPhone = (phone) => {
//     return /^[0-9]{10}$/.test(phone);
//   };

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs.sendForm('service_2rfbauv', 'template_29vhd8p', form.current, 'f1Jp2rgMLEcGrCvKw')
//       .then(
//         () => {
//           console.log('SUCCESS!');
//         },
//         (error) => {
//           console.log('FAILED...', error.text);
//         }
//       );
//   };

//   return (
//     <form ref={form} onSubmit={sendEmail}>
//       <section id="form_wrapper">
//         <div className="form_container">
//           <div className="row input-container">
//             <div id="form_header">
//               <h5>Get in Touch</h5>
//               <h1>Let's collaborate, Reach Out to us</h1>
//               <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint sapiente voluptas odio eaque sunt veritatis?</p>
//             </div>
//             <div className="col-xs-12">
//               <div className="styled-input wide">
//                 <input
//                   type="text"
//                   name="form_name"
//                   value={formData.form_name}
//                   onChange={handleChange}
//                   required
//                 />
//                 <label>Name</label>
//                 {errors.form_name && <span className="error">{errors.form_name}</span>}
//               </div>
//             </div>
//             <div className="col-md-6 col-sm-12">
//               <div className="styled-input">
//                 <input
//                   type="email"
//                   name="form_email"
//                   value={formData.form_email}
//                   onChange={handleChange}
//                   required
//                 />
//                 <label>Email</label>
//                 {errors.form_email && <span className="error">{errors.form_email}</span>}
//               </div>
//             </div>
//             <div className="col-md-6 col-sm-12">
//               <div className="styled-input">
//                 <input
//                   type="text"
//                   name="phone"
//                   value={formData.phone}
//                   onChange={handleChange}
//                   required
//                 />
//                 <label>Phone Number</label>
//                 {errors.phone && <span className="error">{errors.phone}</span>}
//               </div>
//             </div>
//             <div className="col-xs-12">
//               <div className="styled-input wide">
//                 <textarea
//                   name="message"
//                   value={formData.message}
//                   onChange={handleChange}
//                   required
//                 ></textarea>
//                 <label>Message</label>
//                 {errors.message && <span className="error">{errors.message}</span>}
//               </div>
//             </div>
//             <div className="col-xs-12 d-flex justify-content-center">
//               <button className="submit-btn" onClick={handleSubmit}>
//                 <img src="./paperpln.png" width={50} className='paper-plane' alt="Paper Plane Icon"/>
//               </button>
//             </div>
//           </div>
//         </div>
//       </section>
//     </form>
//   );
// };

// export default ContactForm;
import React, { useState, useRef } from 'react';
import './Connect.css';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    from_phone: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const form = useRef();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      console.log('Form submitted:', formData);
      sendEmail(e);
      setErrors({});
      setFormData({
        from_name: '',
        from_email: '',
        from_phone: '',
        message: ''
      });
    } else {
      setErrors(errors);
    }
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.from_name.trim()) {
      errors.from_name = 'Name is required';
    }
    if (!data.from_email.trim()) {
      errors.from_email = 'Email is required';
    } else if (!isValidEmail(data.from_email)) {
      errors.from_email = 'Invalid email format';
    }
    if (!data.from_phone.trim()) {
      errors.from_phone = 'Phone number is required';
    } else if (!isValidPhone(data.from_phone)) {
      errors.from_phone = 'Invalid phone number';
    }
    if (!data.message.trim()) {
      errors.message = 'Message is required';
    }
    return errors;
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(email);
  };

  const isValidPhone = (phone) => {
    return /^[0-9]{10}$/.test(phone);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    console.log('Sending email with data:', formData); // Debug log

    emailjs.sendForm('service_2ybl6p5', 'template_t1t94kx', form.current, 'RkB3Mua_olbg3UaQe')
      .then(
        (result) => {
          console.log('SUCCESS!', result.status, result.text);
          window.alert('Form successfully submitted');
        },
        (error) => {
          console.error('FAILED...', error);
          window.alert('Failed to send form. Please try again later.');
        }
      );
  };

  return (
    <form ref={form} onSubmit={handleSubmit}>
      <section id="form_wrapper">
        <div className="form_container">
          <div className="row input-container">
            <div id="form_header">
              <h5>Get in Touch</h5>
              <h1>Let's collaborate, Reach Out to us</h1>
            </div>
            <div className="col-xs-12">
              <div className="styled-input wide">
                <input
                  type="text"
                  name="from_name"
                  value={formData.from_name}
                  onChange={handleChange}
                  required
                />
                <label>Name</label>
                {errors.from_name && <span className="error">{errors.from_name}</span>}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="styled-input">
                <input
                  type="email"
                  name="from_email"
                  value={formData.from_email}
                  onChange={handleChange}
                  required
                />
                <label>Email</label>
                {errors.from_email && <span className="error">{errors.from_email}</span>}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="styled-input">
                <input
                  type="text"
                  name="from_phone"
                  value={formData.from_phone}
                  onChange={handleChange}
                  required
                />
                <label>Phone Number</label>
                {errors.from_phone && <span className="error">{errors.from_phone}</span>}
              </div>
            </div>
            <div className="col-xs-12">
              <div className="styled-input wide">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
                <label>Message</label>
                {errors.message && <span className="error">{errors.message}</span>}
              </div>
            </div>
            <div className="col-xs-12 d-flex justify-content-center">
              <button className="submit-btn" type="submit">
                <img src="./paperpln.png" width={50} className='paper-plane' alt="Paper Plane Icon"/>
              </button>
            </div>
          </div>
        </div>
      </section>
    </form>
  );
};

export default ContactForm;
