import React from "react";
import './Cloud.css';
import Cldslider from './Cldslider'
import Cldstk from "./Cldstk";
import { Link } from 'react-router-dom';
export default function cloud() {
    return (

        <div id="cld_wrp">
            <div className="cldhr"><Cldslider/></div>
            <div id="cld_services">
        <h1>What we provide?</h1>
        <div id="cld_services_cntnr">
          <div className="cld_services_cntnt">
            <div className="cldsev_img">
              <img src="./serv_imgs/serv_icons/sass.png" />
            </div>
            <div className="cldsev_pass">
              <h1>Software as a Service (SaaS)</h1>
              <p>
Software as a Service (SaaS) provides cloud-based applications for productivity, CRM, security, data analytics, and content management. Additional services include managed cloud infrastructure, disaster recovery, IoT management, and AI/ML tools.</p>
            </div>
          </div>
          <div className="cld_services_cntnt">
            <div className="cldsev_img">
              <img src="./serv_imgs/serv_icons/vm.png" />
            </div>
            <div className="cldsev_pass">
              <h1>Infrastructure as a Service</h1>
              <p>VMs offer scalable, on-demand computing. Cloud storage provides durable, scalable backup and recovery. Compute clusters deliver powerful HPC resources. Networking ensures secure, reliable connectivity.</p>
            </div>
          </div>
          <div className="cld_services_cntnt">
            <div className="cldsev_img">
              <img src="./serv_imgs/serv_icons/pass.png" />
            </div>
            <div className="cldsev_pass">
              <h1>Platform as a Service</h1>
              <p>Platform as a Service (PaaS) enables development and deployment of web applications and APIs with managed databases, containerization tools, and serverless computing for running code on a pay-per-use basis without server management.
              </p>
            </div>
          </div>
          
          <div className="cld_services_cntnt">
            <div className="cldsev_img">
              <img src="./serv_imgs/serv_icons/strg.png" />
            </div>
            <div className="cldsev_pass">
              <h1>Storage Services</h1>
              <p>Cloud storage services offer scalable, durable, and secure solutions for data backup, archiving, and disaster recovery, ensuring high availability and reliability.</p>
            </div>
          </div>
          <div className="cld_services_cntnt">
            <div className="cldsev_img">
              <img src="./serv_imgs/serv_icons/monitoring.png" />
            </div>
            <div className="cldsev_pass">
              <h1>Management and Monitoring</h1>
              <p>Cloud management and monitoring provide tools for resource allocation, performance tracking, and automated alerts, ensuring efficient and optimized cloud operations.</p>
            </div>
          </div>
        </div>
      </div>
            <div><Cldstk/></div>
            <section id="cld_prc_sec">
        <h1>Pricing</h1>
        <div id="cld_prc_cntnr">
          <div id="cld_prc_cntnt"  className="cld_basic">
            <h1>Basic</h1>
            {/* <h1> $200 </h1> */}
            <hr />
            <h2>storage setup</h2>
            <Link to="/Connect">
            <button class="btn">Get started</button>
            </Link>

          </div>
          <div id="cld_prc_cntnt"  className="cld_pro">
            <h1>Pro</h1>
            {/* <h1> $1000</h1> */}
            <hr />
            <h2>comprehensive solutions</h2>
            <Link to="/Connect">
            <button class="btn">Get started</button>
            </Link>
          </div>
          <div id="cld_prc_cntnt"  className="cld_adv">
            <h1>Advanced</h1>
            {/* <h1>$500</h1> */}
            <hr />
            <h2>storage and security</h2>
            <Link to="/Connect">
            <button class="btn">Get started</button>
            </Link>
          </div>
        </div>
      </section>
        </div>
        
    )
}