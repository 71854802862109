// import React from 'react';
// import './hmtmln.css';

// export default function hmtmln() {
//     return (
//         <section id="sec2">
//             <h1>our journey</h1>

//             <div id="wvtmln_cntnr" style={{ alignItems: 'center' }}>
//                 <ul className="jrny_row">
//                     <li >
//                         <div className="media-body">
//                             <img src="https://ouch-cdn2.icons8.com/ba5ulpgsTfmJq5pw6Yv97sjHCh52NiKl1KFNOAHKd54/rs:fit:368:321/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9wbmcvODg3/Lzk3ZmIxZTMzLTc1/MjItNGNlZS1iMGIw/LTBkZmZjYWYxMDYx/ZS5wbmc.png" className='tmln_ilstr' />
//                             <h2>2019</h2>
//                             <h1>A Dream Takes Flight</h1>
//                             <p>In the heart of Chennai</p>
//                             <img src="process-line-2.png" className='prcln1' />
//                         </div>
//                     </li>
//                     <li>
//                         <div className="media-body">
//                             <img src="https://ouch-cdn2.icons8.com/MO9qQ9IR_4MY9jdmpxvO7zJhtHz_Mu2tEX9EP4gLft0/rs:fit:368:288/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9wbmcvMzM2/L2M0MmZhYWQzLWIx/YzMtNGI5NS04YzBk/LWI5MDQ2MmUxZDRm/Yi5wbmc.png" className='tmln_ilstr' />
//                             <h2>2020</h2>
//                             <h1>Growth and Collaboration</h1>
//                             <p>We forged strategic partnerships with like-minded organizations </p>
//                         </div>
//                     </li>
//                     <li>
//                         <div className="media-body" >
//                             <img src="process-line-1.png" className='prcln2' />
//                             <img src="https://ouch-cdn2.icons8.com/O3Qh61GXtj6bk5wYsEv1hfaHVyBmhlNku8ee21O_gYE/rs:fit:368:475/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9wbmcvNjU1/LzJlMzYzZTdjLTkz/YjEtNGE0YS05NThl/LTgxNjgyMDYwZWU1/Ny5wbmc.png" className='tmln_ilstr' />
//                             <h2>2021</h2>
//                             <h1>Recognition and Reinvention</h1>
//                             <p> Embracing new technologies and reinventing ourselves for the digital age</p>
//                             <img src="process-line-2.png" className='prcln3' />
//                         </div>
//                     </li>
//                     <li>
//                         <div className="media-body">
//                             <img src="https://ouch-cdn2.icons8.com/h8WKRPDLoiQHIFcrqyhwGqRkIirn4lftUMqKbYqVaEc/rs:fit:368:308/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9wbmcvMTg5/Lzk2ZGJlZGYzLWVl/NjQtNDdmZi1iOWQ5/LTM5ZDE5ZGQ3NTIz/ZC5wbmc.png" className='tmln_ilstr' />
//                             <h2>2022</h2>
//                             <h1>Trailblazing Innovation</h1>
//                             <p>Our innovative solutions helped our clients stay ahead</p>
//                         </div>
//                     </li>
//                     <li>
//                         <div className="media-body">
//                             <img src="process-line-1.png" className='prcln4' />
//                             <img src="https://ouch-cdn2.icons8.com/XCoDPK_cA9Jeloy-vyv8WNdzNe8LNimpO9WFnJ20Qxw/rs:fit:368:370/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9wbmcvMTAy/Lzc2NGE4MjIzLThj/MjQtNDAxMy1iYmYz/LTRiNWE2ZWQ5YjQ5/OC5wbmc.png" className='tmln_ilstr' />
//                             <h2>2023</h2>
//                             <h1> Expansion and Exploration</h1>
//                             <p>Our global footprint grew, but our commitment to our clients remained steadfast</p>
//                             <img src="process-line-2.png" className='prcln5' />
//                         </div>
//                     </li>
//                     <li>
//                         <div className="media-body">
//                             <img src="https://ouch-cdn2.icons8.com/KXxqmkyJ0BtDROpUWp9iufSNNlGd8aVpB37logVRiVw/rs:fit:368:572/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9wbmcvNzkw/LzcyODhhMmI2LTA4/N2EtNDc2ZS04ZGJl/LTQwNjA1ZmM3MWQ2/NC5wbmc.png" className='tmln_ilstr' />
//                             <h2>2024</h2>
//                             <h1>Celebrating Five Years of Success</h1>
//                             <p>we celebrate our fifth anniversary, we reflect on how far we've come and look forward to the journey ahead. </p>
//                         </div>
//                     </li>
//                 </ul>
//             </div>
//         </section>
//     )
// }
import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './hmtmln.css';

export default function Hmtmln() {
    useEffect(() => {
        const elements = document.querySelectorAll('.jrny_row li');
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        const callback = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (entry.target.classList.contains('even')) {
                        entry.target.classList.add('fade-in-right');
                    } else {
                        entry.target.classList.add('fade-in-left');
                    }
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);
        elements.forEach(element => {
            observer.observe(element);
        });
    }, []);

    return (
        <section id="sec2">
            <h1>Our Journey</h1>
            <div id="wvtmln_cntnr" style={{ alignItems: 'center' }}>
                <ul className="jrny_row">
                    <li>
                        <div className="media-body">
                            <img src="./timeline-1.png" className='tmln_ilstr' />
                            <h2>2019</h2>
                            <h1>A Dream Takes Flight</h1>
                            <p>In the heart of Chennai</p>
                            <img src="process-line-2.png" className='prcln1' />
                        </div>
                    </li>
                    <li className="even">
                        <div className="media-body">
                            <img src="./timeline-2.png" className='tmln_ilstr' />
                            <h2>2020</h2>
                            <h1>Growth and Collaboration</h1>
                            <p>We forged strategic partnerships with like-minded organizations </p>
                        </div>
                    </li>
                    <li>
                        <div className="media-body">
                            <img src="process-line-1.png" className='prcln2' />
                            <img src="./timeline-3.png" className='tmln_ilstr' />
                            <h2>2021</h2>
                            <h1>Recognition and Reinvention</h1>
                            <p> Embracing new technologies and reinventing ourselves for the digital age</p>
                            <img src="process-line-2.png" className='prcln3' />
                        </div>
                    </li>
                    <li className="even">
                        <div className="media-body">
                            <img src="./timeline-4.png" className='tmln_ilstr' />
                            <h2>2022</h2>
                            <h1>Trailblazing Innovation</h1>
                            <p>Our innovative solutions helped our clients stay ahead</p>
                        </div>
                    </li>
                    <li>
                        <div className="media-body">
                            <img src="process-line-1.png" className='prcln4' />
                            <img src="./timeline-5.png" className='tmln_ilstr' />
                            <h2>2023</h2>
                            <h1> Expansion and Exploration</h1>
                            <p>Our global footprint grew, but our commitment to our clients remained steadfast</p>
                            <img src="process-line-2.png" className='prcln5' />
                        </div>
                    </li>
                    <li className="even">
                        <div className="media-body">
                            <img src="./timeline-6.png" className='tmln_ilstr' />
                            <h2>2024</h2>
                            <h1>Celebrating Five Years of Success</h1>
                            <p>We celebrate our fifth anniversary, we reflect on how far we've come and look forward to the journey ahead.</p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    );
}


