import React from 'react';
import '../../App.css'
import Abt from '../component/Abt';


const About=()=> {  
  return (
    <>
      <Abt/>
    </>
  );
}
export default About;