import React from "react";
import Hmtmln from "../component/hmtmln";
import '../pg_css/Home.css'




const Home = () => {

    return (
            <main id="min1" class="embed-responsive embed-responsive-21by9">
                <div><div id="hero_cntnr"><video src='./Lux_hero.mp4' autoPlay type="video/mp4" id="hero_bgvid"></video></div></div>
                <div><Hmtmln/></div>
            </main>
        
    );
}

export default Home;