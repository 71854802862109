// import React, { useState, useEffect } from 'react';
// import './Appslider.css';

// const Appslider = () => {
//   const [images, setImages] = useState([
//     './serv_imgs/app_imgs/app1.jpg',
//     './serv_imgs/app_imgs/app2.jpg',
//     './serv_imgs/app_imgs/app3.jpg',
//     './serv_imgs/app_imgs/app4.jpg',
//     './serv_imgs/app_imgs/app5.jpg',
//     './serv_imgs/app_imgs/app6.jpg',
//     './serv_imgs/app_imgs/app7.jpg',
//     './serv_imgs/app_imgs/app8.jpg',
//     './serv_imgs/app_imgs/app9.jpg',
//     './serv_imgs/app_imgs/app10.jpg',
//     './serv_imgs/app_imgs/app11.jpg',
//   ]);

//   const [currentIndex, setCurrentIndex] = useState(0);

//   const nextSlide = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
//   };

//   const prevSlide = () => {
//     setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       nextSlide();
//     }, 5000);

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="appslider-container">
//       <div className="button-container">
//         <button onClick={prevSlide}><a class="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
//         <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
//        </a></button>
//        <a class="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
//        <img src='./serv_imgs/serv_icons/slider.png'/>
//        </a>
//         <button onClick={nextSlide}><a class="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
//         <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
//       </a></button>
//       </div>
//       <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
//     </div>
//   );
// };

// export default Appslider;
import React, { useState, useEffect } from 'react';
import './Appslider.css';

const Appslider = () => {
  const [images, setImages] = useState([
    './serv_imgs/app_imgs/app1.jpg',
    './serv_imgs/app_imgs/app2.jpg',
    './serv_imgs/app_imgs/app3.jpg',
    './serv_imgs/app_imgs/app4.jpg',
    './serv_imgs/app_imgs/app5.jpg',
    './serv_imgs/app_imgs/app6.jpg',
    './serv_imgs/app_imgs/app7.jpg',
    './serv_imgs/app_imgs/app8.jpg',
    './serv_imgs/app_imgs/app9.jpg',
    './serv_imgs/app_imgs/app10.jpg',
    './serv_imgs/app_imgs/app11.jpg',
  ]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    },25000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="appslider-container">
      <div className="button-container">
        <button onClick={prevSlide} className="arrow-button">
          <img src='./serv_imgs/serv_icons/slider1.png' alt="Previous Slide" className="arrow-icon1" />
        </button>
        <button onClick={nextSlide} className="arrow-button">
          <img src='./serv_imgs/serv_icons/slider2.png' alt="Next Slide" className="arrow-icon2" />
        </button>
      </div>
      <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="slide-image" />
    </div>
  );
};

export default Appslider;
