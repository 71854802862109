import React from 'react';
import Dgtcarus from'./Dgtcarus.jsx';
import './Dgtstk.css';
const Dgtltechstk = () => {
  return (
   <div id="dgtltech_stk_cntnr">
    <div id="dgtl_tecsec">
<div id="dgtl_debts">
 <div id="dgtltechstk_hd"><h1>Technology stack</h1></div>
 <div id="dgtlcrs_cntnr">
 <Dgtcarus/>
 </div>
 </div>
 </div>
 </div>
  )
}

export default Dgtltechstk 