import React from 'react';
import './Web.css';
import Tech_stack from './Tech_stack';
import Webslider from './webslider';
import { Link } from 'react-router-dom';


export default function web() {
  return (
  <div id="web_wrapper">
     <div><Webslider/></div>
     <div id="web_services">
        <h1>What we provide?</h1>
        <div id="web_services_cntnr">
          <div className="web_services_cntnt">
            <div className="websev_img">
              <img src="./serv_imgs/serv_icons/website.png" />
            </div>
            <div className="websev_pass" >
              <h1>Full Stack Development</h1>
              <p>Full stack development involves building both front-end and back-end applications, encompassing user interfaces, server logic, databases, and APIs for a complete and functional application.</p>
            </div>
          </div>
          <div className="web_services_cntnt">
            <div className="websev_img">
              <img src="./serv_imgs/serv_icons/ecommerce.png" />
            </div>
            <div className="websev_pass">
              <h1>E-commerce Development</h1>
              <p>We create custom e-commerce solutions to streamline your online sales process and provide a secure and user-friendly shopping experience.</p>
            </div>
          </div>
          <div className="web_services_cntnt">
            <div className="websev_img">
              <img src="./serv_imgs/serv_icons/responsive-page.png" />
            </div>
            <div className="websev_pass">
              <h1>Responsive Web development</h1>
              <p> We design and develop websites that adapt seamlessly to all devices (desktop, tablet, mobile) for optimal user experience across platforms.</p>
            </div>
          </div>
          <div className="web_services_cntnt">
            <div className="websev_img">
              <img src="./serv_imgs/serv_icons/backend.png" />
            </div>
            <div className="websev_pass">
              <h1>Back-end Development</h1>
              <p> Our back-end developers ensure a robust website foundation with secure databases, server-side scripting, and seamless API integrations</p>
            </div>
          </div>
        </div>
      </div>
   <section id="web_sec2" >
    <div id="web_featrs_title"><h1>How we sculpt your website</h1></div>
   <div id="web_scult_container" >
   <div id="web_sclpt">
        <img src="./serv_imgs/serv_icons/deve.png" />
        <h3 className='web_sculpt_heading'>Smart Development</h3>
    <div className="text-reveal">
        <p>Smart development leverages innovative technologies and efficient methodologies to create scalable, sustainable solutions that meet evolving needs.</p>
    </div>
</div>
    <div class="vertical-line"></div>
    <div id="web_sclpt">
    <img src="./serv_imgs/serv_icons/colob.png" className='web_sculpt_img'/>
    <h3 className='web_sculpt_heading'>Real time collaboration</h3>
     <div className='text-reveal'>
    <p>Enhance productivity with real-time collaboration tools. Seamlessly work together on projects from any location.</p>
    </div>
    </div>
    <div class="vertical-line"></div>
    <div id="web_sclpt">
    <img src="./serv_imgs/serv_icons/intg.png"className='web_sculpt_img'/>
    <h3>Multi platform Integration</h3>
    <div className='text-reveal'>
    <p>"Seamlessly integrate across multiple platforms for enhanced efficiency and connectivity."</p>
    </div>
    </div>
    <div class="vertical-line"></div>
    <div id="web_sclpt">
    <img src="./serv_imgs/serv_icons/anal.png" className='web_sculpt_img'/>
    <h3>Interactive Analysis</h3>
    <div className='text-reveal'>
    <p>"Engage with data through interactive analysis for deeper insights and informed decision-making."</p>
    </div>
    </div>
    </div>
   </section>
   
   <div><Tech_stack/></div>
   <section id="web_prc_sec" >
        <h1>Pricing</h1>
        <div id="web_prc_cntnr">
          <div id="web_prc_cntnt"className='web_basic'>
            <h1>Basic</h1>
            {/* <h1>$400</h1> */}
            <hr />
            <h2>single-page</h2>
            <Link to="/Connect">
            <button className="btn">Get started</button>
            </Link>
          </div>
          <div id="web_prc_cntnt" className='web_pro'>
            <h1>Pro</h1>
            {/* <h1>$2000</h1> */}
            <hr />
            <h2>custom functionalities</h2>
            <Link to="/Connect" >
            <button className="btn">Get started</button>
            </Link>
          </div>
          <div id="web_prc_cntnt" className='web_adv'>
            <h1>Advanced</h1>
            {/* <h1>$900 </h1> */}
            <hr />
            <h2>multi-page with basic e-commerce</h2>
            <Link to="/Connect" >
            <button class="btn">Get started</button>
            </Link>
          </div>
        </div>
      </section>
  </div>)
}
